import { Vue } from "vue-property-decorator"
import { GlobalIndicatorsDataset } from "@/model/interfaces/globalIndicators/globalIndicatorsDataset"
import { GlobalIndicatorsDatasetKey } from "@/model/enum/globalIndicators/globalIndicatorsDatasetKey"
import { GlobalIndicatorsAgePyramidFormatter } from "./globalIndicatorsAgePyramidFormatter"

export default class GlobalIndicatorsService {
  private static GLOBAL_INDICATORS_URI = "global_indicators";

  static async get(year: number | null, region: string | null, country: string | null): Promise<GlobalIndicatorsDataset> {
    // if (country !== null && region !== null) {
    //   throw new Error("Country and region cannot both be set. Please specify only one.");
    // }

    const params: string[] = [];

    if (year !== null) {
      params.push("year=" + year);
    } 
    if (region !== null) {
      params.push("region=" + region);
    }   
    if (country !== null) {
      params.push("countries=" + country);
    }

    this.GLOBAL_INDICATORS_URI = "global_indicators" + (params.length ? "?" + params.join("&") : "");

    return Vue.$axios.get(this.GLOBAL_INDICATORS_URI).then(response => {
      const BENEFICIARIES_AGE_PYRAMID_BY_AOW = 'beneficiariesAgePyramidByAOW';
      response.data[GlobalIndicatorsDatasetKey.BENEFICIARIES_AGE_PYRAMID_BY_AOW_AGRICULTURE] = GlobalIndicatorsAgePyramidFormatter.format(response.data[BENEFICIARIES_AGE_PYRAMID_BY_AOW]).O1;
      response.data[GlobalIndicatorsDatasetKey.BENEFICIARIES_AGE_PYRAMID_BY_AOW_SELF] = GlobalIndicatorsAgePyramidFormatter.format(response.data[BENEFICIARIES_AGE_PYRAMID_BY_AOW]).O2;
      response.data[GlobalIndicatorsDatasetKey.BENEFICIARIES_AGE_PYRAMID_BY_AOW_WAGE] = GlobalIndicatorsAgePyramidFormatter.format(response.data[BENEFICIARIES_AGE_PYRAMID_BY_AOW]).O3;
      response.data[GlobalIndicatorsDatasetKey.REGIONS_NAMES] = Object.keys(response.data[GlobalIndicatorsDatasetKey.REGIONS_LIST]);
      return response.data as GlobalIndicatorsDataset;
    });
  }
}

