import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators"
import store from "@/store"
import { GlobalIndicatorsDataset } from "@/model/interfaces/globalIndicators/globalIndicatorsDataset"
import GlobalIndicatorsService from "@/services/globalIndicators/globalIndicatorsService"
import { GlobalIndicatorsCategoryMainTrends } from "@/model/interfaces/globalIndicators/globalIndicatorsCategoryMainTrends"
import { GlobalIndicatorsDatasetKey } from "@/model/enum/globalIndicators/globalIndicatorsDatasetKey"
import {
  BarChartFormatter,
  PieChartFormatter,
  ProportionnalSVGArcCreator,
  PyramidAgeChartFormatter,
  TreeMapChartFormatter
} from "@/services/globalIndicators/globalIndicatorsChartDataFormatter"
import { ChartConfiguration } from "chart.js"
import { GlobalIndicatorsChartDefinitions } from "@/services/globalIndicators/GlobalIndicatorsChartDefinitions"
import { GlobalIndicatorsTitleStats } from "@/services/globalIndicators/globalIndicatorsTitleStats"
import { LVColors } from "@/plugins/vuetify"

@Module({ dynamic: true, store, name: "GlobalIndicatorsModule", namespaced: true })
class GlobalIndicatorsModule extends VuexModule {
  loadingData = true
  dataset: GlobalIndicatorsDataset | null = null
  selectedYear: number | null = null
  selectedRegion: string | null = null
  selectedCountry: string | null = null
  selectableCountriesList: string[] | null = null

  @Action
  async getDataset() {
    this.setLoading(true)
    const dataset = await GlobalIndicatorsService.get(this.selectedYear, this.selectedRegion, this.selectedCountry)
    this.setLoading(false)
    this.setDataset(dataset)
    this.setSelectableCountriesList()
  }

  @Mutation
  public setDataset(dataset: GlobalIndicatorsDataset): void {
    this.dataset = dataset
  }

  @Action
  updateSelectedYear(year: number | null): void {
    this.context.commit("setselectedYear", year)
    this.getDataset()
  }

  @Action
  updateSelectedRegion(region: string | null): void {
    this.context.commit("setselectedCountry", null)
    this.context.commit("setselectedRegion", region)
    this.context.commit("setSelectableCountriesList")
    this.getDataset()
  }

  @Action
  updateSelectedCountry(country: string | null): void {
    this.context.commit("setselectedCountry", country)
    this.getDataset()
  }

  @Mutation
  public setSelectableCountriesList() {
    if (this.selectedRegion) {
      const regionCountries = (this.dataset?.regionsList as Record<string, any>)[this.selectedRegion as string] || [];
      this.selectableCountriesList = this.dataset!.countriesList.filter(country => 
        regionCountries.includes(country)
      )
    } else {
      this.selectableCountriesList = this.dataset!.countriesList
    }
  }

  @Mutation
  public setselectedYear(year: number | null): void {
    this.selectedYear = year
  }

  @Mutation
  public setselectedRegion(region: string | null): void {
    this.selectedRegion = region
  }

  @Mutation
  public setselectedCountry(country: string | null): void {
    this.selectedCountry = country
  }

  @Mutation
  public setLoading(status: boolean): void {
    this.loadingData = status
  }

  get benefeciariesSurveyedTitleStats(): GlobalIndicatorsCategoryMainTrends | null {
    if (this.dataset) {
      const start = this.dataset[GlobalIndicatorsDatasetKey.BASELINE_BENEFICIARIES_COUNT]
      const end = this.dataset[GlobalIndicatorsDatasetKey.ENDLINE_BENEFICIARIES_COUNT]
      return GlobalIndicatorsTitleStats.getStats(start, end, "Survey attrition rate")
    }
    return null
  }

  get surveyedChartsConfig(): Partial<Record<GlobalIndicatorsDatasetKey, ChartConfiguration>> {
    if (this.dataset) {
      // Setup initial generic configuration
      const benefeciariesSurveyedChartsConfig: Partial<Record<GlobalIndicatorsDatasetKey, ChartConfiguration>> = {
        [GlobalIndicatorsDatasetKey.BENEFICIARIES_LEGAL_STATUS_COUNT]: PieChartFormatter.format(
          GlobalIndicatorsDatasetKey.BENEFICIARIES_LEGAL_STATUS_COUNT,
          this.dataset,
          "category",
          "percentage",
          "pie"
        ),
        [GlobalIndicatorsDatasetKey.FAMILIES_SIZE_COUNT]: BarChartFormatter.format(GlobalIndicatorsDatasetKey.FAMILIES_SIZE_COUNT, this.dataset, "nb_peoples", "count", false),
        [GlobalIndicatorsDatasetKey.ORIGIN_COUNTRIES_COUNT]: TreeMapChartFormatter.format(this.dataset[GlobalIndicatorsDatasetKey.ORIGIN_COUNTRIES_COUNT]) as ChartConfiguration,
        [GlobalIndicatorsDatasetKey.LAND_LEGAL_ACCESS]: PieChartFormatter.format(
          GlobalIndicatorsDatasetKey.LAND_LEGAL_ACCESS,
          this.dataset,
          "land_access",
          "percentage",
          "doughnut"
        ),
        [GlobalIndicatorsDatasetKey.EMPLOYMENT_LEGAL_ACCESS]: PieChartFormatter.format(
          GlobalIndicatorsDatasetKey.EMPLOYMENT_LEGAL_ACCESS,
          this.dataset,
          "employment_access",
          "percentage",
          "doughnut"
        ),
        [GlobalIndicatorsDatasetKey.FINANCIAL_LEGAL_ACCESS]: PieChartFormatter.format(
          GlobalIndicatorsDatasetKey.FINANCIAL_LEGAL_ACCESS,
          this.dataset,
          "employment_access",
          "percentage",
          "doughnut"
        ),
        [GlobalIndicatorsDatasetKey.AOW_REPARTITION]: PieChartFormatter.format(GlobalIndicatorsDatasetKey.AOW_REPARTITION, this.dataset, "aow", "percentage", "doughnut"),
        [GlobalIndicatorsDatasetKey.AGRICULTURAL_ACTIVITIES_INVOLVEMENT]: PieChartFormatter.format(
          GlobalIndicatorsDatasetKey.AGRICULTURAL_ACTIVITIES_INVOLVEMENT,
          this.dataset,
          "category",
          "percentage",
          "doughnut"
        ),
        [GlobalIndicatorsDatasetKey.BENEFICIARIES_EMPLOYMENT_TYPE]: PieChartFormatter.format(
          GlobalIndicatorsDatasetKey.BENEFICIARIES_EMPLOYMENT_TYPE,
          this.dataset,
          "category",
          "percentage",
          "doughnut"
        ),
        [GlobalIndicatorsDatasetKey.BENEFICIARIES_AGE_PYRAMID_BY_AOW_AGRICULTURE]: PyramidAgeChartFormatter.format(
          this.dataset[GlobalIndicatorsDatasetKey.BENEFICIARIES_AGE_PYRAMID_BY_AOW_AGRICULTURE]
        ),
        [GlobalIndicatorsDatasetKey.BENEFICIARIES_AGE_PYRAMID_BY_AOW_SELF]: PyramidAgeChartFormatter.format(
          this.dataset[GlobalIndicatorsDatasetKey.BENEFICIARIES_AGE_PYRAMID_BY_AOW_SELF],
          false
        ),
        [GlobalIndicatorsDatasetKey.BENEFICIARIES_AGE_PYRAMID_BY_AOW_WAGE]: PyramidAgeChartFormatter.format(
          this.dataset[GlobalIndicatorsDatasetKey.BENEFICIARIES_AGE_PYRAMID_BY_AOW_WAGE],
          false
        ),
        [GlobalIndicatorsDatasetKey.DISPLACED_BENEFICIARIES_EMPLOYMENT_BY_LINE]: BarChartFormatter.format(
          GlobalIndicatorsDatasetKey.DISPLACED_BENEFICIARIES_EMPLOYMENT_BY_LINE,
          this.dataset,
          ["Baseline", "Endline"],
          "",
          false,
          true
        ),
        [GlobalIndicatorsDatasetKey.HOST_COMMUNITY_BENEFICIARIES_EMPLOYMENT_BY_LINE]: BarChartFormatter.format(
          GlobalIndicatorsDatasetKey.HOST_COMMUNITY_BENEFICIARIES_EMPLOYMENT_BY_LINE,
          this.dataset,
          ["Baseline", "Endline"],
          "",
          false,
          true
        ),
        [GlobalIndicatorsDatasetKey.FEMALE_BENEFICIARIES_EMPLOYMENT_BY_LINE]: BarChartFormatter.format(
          GlobalIndicatorsDatasetKey.FEMALE_BENEFICIARIES_EMPLOYMENT_BY_LINE,
          this.dataset,
          ["Baseline", "Endline"],
          "",
          false,
          true
        ),
        [GlobalIndicatorsDatasetKey.MALE_BENEFICIARIES_EMPLOYMENT_BY_LINE]: BarChartFormatter.format(
          GlobalIndicatorsDatasetKey.MALE_BENEFICIARIES_EMPLOYMENT_BY_LINE,
          this.dataset,
          ["Baseline", "Endline"],
          "",
          false,
          true
        ),
        [GlobalIndicatorsDatasetKey.SELF_EMPLOYMENT_IN_AND_OUT_AGRICULTURE]: BarChartFormatter.format(
          GlobalIndicatorsDatasetKey.SELF_EMPLOYMENT_IN_AND_OUT_AGRICULTURE,
          this.dataset,
          ["Baseline", "Endline"],
          "",
          false,
          true
        ),
        [GlobalIndicatorsDatasetKey.WAGE_EMPLOYMENT_IN_AND_OUT_AGRICULTURE]: BarChartFormatter.format(
          GlobalIndicatorsDatasetKey.WAGE_EMPLOYMENT_IN_AND_OUT_AGRICULTURE,
          this.dataset,
          ["Baseline", "Endline"],
          "",
          false,
          true
        ),
        [GlobalIndicatorsDatasetKey.EMPLOYMENT_SUSTAINABILITY_IN_AGRICULTURE]: PieChartFormatter.format(
          GlobalIndicatorsDatasetKey.EMPLOYMENT_SUSTAINABILITY_IN_AGRICULTURE,
          this.dataset,
          "category",
          "percentage",
          "pie"
        ),
        [GlobalIndicatorsDatasetKey.EMPLOYMENT_SUSTAINABILITY_OTHERS]: PieChartFormatter.format(
          GlobalIndicatorsDatasetKey.EMPLOYMENT_SUSTAINABILITY_OTHERS,
          this.dataset,
          "category",
          "percentage",
          "pie"
        ),
        [GlobalIndicatorsDatasetKey.SECONDARY_EMPLOYMENT_TYPES]: PieChartFormatter.format(
          GlobalIndicatorsDatasetKey.SECONDARY_EMPLOYMENT_TYPES,
          this.dataset,
          "category",
          "percentage",
          "pie"
        ),
        [GlobalIndicatorsDatasetKey.INCOME_CHANGE_BY_LINE]: BarChartFormatter.format(
          GlobalIndicatorsDatasetKey.INCOME_CHANGE_BY_LINE,
          this.dataset,
          ["Baseline", "Endline"],
          "",
          false,
          true
        ),
        [GlobalIndicatorsDatasetKey.SAVING_CHANGE_BY_LINE]: BarChartFormatter.format(
          GlobalIndicatorsDatasetKey.SAVING_CHANGE_BY_LINE,
          this.dataset,
          ["Baseline", "Endline"],
          "",
          false,
          true
        ),
        [GlobalIndicatorsDatasetKey.SAVING_SERVICE_ACCESS]: BarChartFormatter.format(
          GlobalIndicatorsDatasetKey.SAVING_SERVICE_ACCESS,
          this.dataset,
          ["Baseline", "Endline"],
          "",
          false,
          true
        ),
        [GlobalIndicatorsDatasetKey.LOAN_SERVICE_ACCESS]: BarChartFormatter.format(
          GlobalIndicatorsDatasetKey.LOAN_SERVICE_ACCESS,
          this.dataset,
          ["Baseline", "Endline"],
          "",
          false,
          true
        ),
        [GlobalIndicatorsDatasetKey.MARKET_ACCESS]: BarChartFormatter.format(
          GlobalIndicatorsDatasetKey.MARKET_ACCESS,
          this.dataset,
          ["Baseline", "Endline"],
          "",
          false,
          true
        )
      }

      // Add additional configuration
      for (const key in benefeciariesSurveyedChartsConfig) {
        benefeciariesSurveyedChartsConfig[key as GlobalIndicatorsDatasetKey]!.data.datasets.map((dataset: any, i) => {
          benefeciariesSurveyedChartsConfig[key as GlobalIndicatorsDatasetKey]!.data.datasets[i] = {
            ...dataset,
            ...GlobalIndicatorsChartDefinitions.getChartDefinitions()[key as GlobalIndicatorsDatasetKey]!.additionalConfig[i]
          }
        })
      }
      return benefeciariesSurveyedChartsConfig
    }
    return {}
  }

  get employmentSurveyedTitleStats(): GlobalIndicatorsCategoryMainTrends | null {
    if (this.dataset) {
      try {
        const start = Math.round(this.dataset[GlobalIndicatorsDatasetKey.BASELINE_ENDLINE_EMPLOYMENT_COMPARISON][0].percentage)
        const end = Math.round(this.dataset[GlobalIndicatorsDatasetKey.BASELINE_ENDLINE_EMPLOYMENT_COMPARISON][1].percentage)
        return GlobalIndicatorsTitleStats.getStats(start, end, "Impact")
      } catch (error) {
        return null
      }
    }
    return null
  }

  get secondaryEmploymentMultipliersSVGs(): SVGElement[] {
    if (this.dataset) {
      return [
        ProportionnalSVGArcCreator.create(this.dataset[GlobalIndicatorsDatasetKey.SECONDARY_EMPLOYMENT_MULTIPLIER].average_overall, LVColors.PRIMARY_BLUE),
        ProportionnalSVGArcCreator.create(this.dataset[GlobalIndicatorsDatasetKey.SECONDARY_EMPLOYMENT_MULTIPLIER].average_agriculture, LVColors.DARK_GREEN),
        ProportionnalSVGArcCreator.create(this.dataset[GlobalIndicatorsDatasetKey.SECONDARY_EMPLOYMENT_MULTIPLIER].average_other, LVColors.DARK_BLUE_1)
      ]
    }
    return []
  }
}

export default getModule(GlobalIndicatorsModule)
